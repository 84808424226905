/* eslint-disable */
import React from "react"
import {
  Link,
  Image,
  Section,
  FormattedMessage,
} from "@pixicommerce/module-components"

import "./landingpage.scss"

const LandingPage = props => {
  const companiesData = props.companiesData

  return (
    <Section className="landing-page">
      <div className="landing-title">
        <FormattedMessage
          id={"landing.title"}
          defaultMessage={
            "Purveyors of the finest internet solutions since 2018"
          }
        />
      </div>
      <div className="landing-info">
        <FormattedMessage
          id={"landing.info"}
          defaultMessage={
            "Pixielity is an internet services company specializing in a range of tools, solutions & services for businesses all over the world. From web hosting and cloud services to voice over IP and customer support platforms . Why not join us today?"
          }
        />
      </div>
      <div className="landing-banners">
        {companiesData.map((item, key) => (
          <Link
            key={key}
            className="landing-banners-link"
            href={`https://${item.link}`}
            target="_blank"
          >
            <div className={"landing-banner " + item.companyClass}>
              <div className="landing-bannerlogo">
                <Image src={item.images} alt={item.name} />
              </div>
              <div className="landing-bannerinfo">
                <FormattedMessage
                  id={"landing.bannerinfo"}
                  defaultMessage={item.description}
                />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </Section>
  )
}

export default LandingPage
