/* eslint-disable */
import {
  pixiediaLogo,
  pixiorageLogo,
  pixiortenLogo,
  pixicommerceLogo,
} from "assets/images"
import { SEO } from "@pixicommerce/module-components"
import LandingPage from "components/Landingpage"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const companiesData = [
      {
        name: "Pixiedia",
        link: "pixiedia.com",
        images: pixiediaLogo,
        companyClass: "pixiedia-box",
        description: "We help shape your ideas and turn into real.",
      },
      {
        name: "Pixicommerce",
        link: "pixicommerce.com",
        images: pixicommerceLogo,
        companyClass: "pixicommerce-box",
        description:
          "a digital ecosystem (PAAS) tailored for mid-size and enterprise businesses.",
      },
      {
        name: "Pixiorage",
        link: "pixiorage.com",
        images: pixiorageLogo,
        companyClass: "pixiorage-box",
        description: "Simple & Powerful Personal Cloud Storage.",
      },
      {
        name: "Pixiorten",
        link: "pixiorten.com",
        images: pixiortenLogo,
        companyClass: "pixiorten-box",
        description: "Simple, powerful & recognizable links.",
      },
    ]

    return (
      <>
        <SEO title="landing.title" />
        <LandingPage companiesData={companiesData} />
      </>
    )
  }
}

export default IndexPage
